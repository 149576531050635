import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Container from '../components/Container';
import Card from '../components/Card';
import Seo from '../components/seo/Seo';
import imgCaricature from '../images/pbcaricature.png';

export default function About({ data: { site, allMdx } }) {
  return (
    <Layout site={site} darkNav>
      <Seo pageTitle="About" />
      <div className="bg-gray-100 sm:px-8 py-8 flex-grow">
        <Container text className="pt-16">
          <Card>
            <div
              style={{ backgroundImage: `url(${imgCaricature})` }}
              className="rounded-full bg-gray-100 w-64 h-64 mx-auto bg-cover bg-center border -mt-16 mb-6"
            ></div>
            <h1 className="text-center leading-tight text-2xl sm:text-4xl font-black mb-8">
              About Beshai Makes
            </h1>
            <p className="mb-8">
              Hi! I'm <Link to="https://peterbeshai.com">Peter Beshai</Link> and
              I like to make things on computers. My main interests revolve
              around web development, data visualization, basketball, creative
              coding, and user experience design. I've had the pleasure of
              working in a variety of cool places related to these interests
              including the{' '}
              <Link href="https://www.nba.com/celtics/">Boston Celtics</Link>,{' '}
              <Link href="https://bocoup.com/services/datavis">Bocoup</Link>,
              and the{' '}
              <Link to="https://www.media.mit.edu/groups/social-machines/overview/">
                MIT Media Lab
              </Link>
              , all of which inform how I go about creating things.
            </p>
            <p className="mb-8">
              I often get asked how to recreate things I've made or advice in
              general about making fun things on a computer and I thought it'd
              be nice to have a spot to put all kinds of instructional material
              about it. So, that's why I made Beshai Makes. I hope to write many
              articles catered to a variety of skill levels, so check back
              often!
            </p>
            <p className="mb-8">
              Everything on this site is designed and made by me (well, except
              the caricature which I was given at a{' '}
              <Link to="https://cortico.ai">Cortico</Link> offsite). The site
              itself was designed in{' '}
              <Link to="https://www.figma.com/files/recent">Figma</Link>, styled
              with <Link to="https://tailwindcss.com/">Tailwind CSS</Link>,
              implemented with{' '}
              <Link to="https://www.gatsbyjs.org/">Gatsby</Link> (with the help
              of{' '}
              <Link to="https://github.com/eggheadio/gatsby-starter-egghead-blog">
                this starter
              </Link>
              ) and hosted on <Link to="https://netlify.com">Netlify</Link>.
            </p>
            <p>
              – <Link to="https://twitter.com/pbesh">@pbesh</Link>
            </p>
          </Card>
        </Container>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`;
